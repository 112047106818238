<template>
  <div>
    <h1>{{ title }}</h1>
    <h3>{{ subtitle }}</h3>
  </div>
</template>

<script>
export default {
  name: "SprTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin: 40px 0 0;
}
h3 {
  margin: 10px 0 0;
  font-size: 16px;
}
</style>