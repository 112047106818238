<template>
  <div class="button" :class="{ disabled }">
    <h4>{{ title }}</h4>
    <h5>{{ subtitle }}</h5>
  </div>
</template>

<script>
export default {
  name: "SprButton",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  display: inline-block;
  width: 320px;
  padding: 20px;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: left;
  color: #2c3e50;
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
@media (max-width: 1000px) {
  .button {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>