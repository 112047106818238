<template>
  <div class="form">
    <SprTitle
      class="mb-5"
      title="Integracja XML"
      subtitle="Integracja XML jest sposobem na przesyłanie dużej ilości ofert z systemów... Maksymalny rozmiar to X MB, pamiętaj aby format był odpowiedni."
    />
    <SprAlert
      v-if="showErrorLabel"
      variant="error"
      :alert-message="errorLabel"
    />
    <SprAlert
      v-if="showSuccessLabel"
      variant="success"
      :alert-message="successLabel"
    />
    <form id="form">
      <div class="row">
        <label>Wybierz plik XML: </label>
        <div class="file-button">
          <div class="file-name">{{ fileName }}</div>
          <input type="file" @change="fileChange" />
        </div>
      </div>
      <div class="row">
        <input class="m-2" @click="handleSubmit" type="submit" value="WYŚLIJ" />
      </div>
    </form>
  </div>
</template>

<script>
import SprTitle from "../components/SprTitle.vue";
import SprAlert from "@/components/SprAlert";
import { form } from "@/composables/form";
export default {
  name: "SprForm",
  components: { SprTitle, SprAlert },
  setup() {
    let {
      fileName,
      showErrorLabel,
      errorLabel,
      showSuccessLabel,
      successLabel,
      fileChange,
      handleSubmit,
    } = form();
    return {
      fileName,
      showErrorLabel,
      errorLabel,
      showSuccessLabel,
      successLabel,
      fileChange,
      handleSubmit,
    };
  },
};
</script>

<style scoped lang="scss">
$error_color: #be4647;
$success_color: #73a83b;
.form {
  max-width: 600px;
  margin: 0 auto;
  form {
    margin: 30px auto;
    width: 400px;
    max-width: 100%;
    text-align: left;
    .row {
      margin-top: 10px;
      label {
        display: block;
      }
      .file-button {
        position: relative;
        display: block;
        width: 100%;
        height: 36px;
        margin-top: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        .file-name {
          position: absolute;
          top: 8px;
          left: 12px;
          z-index: 2;
          opacity: 0.5;
        }
        input[type="file"] {
          position: relative;
          z-index: 3;
          opacity: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
      &.error {
        .file-button {
          border: 1px solid $error_color;
          .file-name {
            padding: 0;
            color: $error_color;
          }
        }
        .error-label {
          margin-top: 10px;
          color: $error_color;
        }
      }
      &.success {
        .file-button {
          border: 1px solid $success_color;
          .file-name {
            opacity: 1;
            color: $success_color;
          }
        }
        .success-label {
          margin-top: 10px;
          color: $success_color;
        }
      }
      input[type="submit"] {
        background: #407bb4;
        border-radius: 5px;
        border: none;
        padding: 10px 20px;
        font-size: 14px;
        color: white;
        cursor: pointer;
        &:hover {
          background: #538bc1;
        }
      }
    }
  }
}
</style>
