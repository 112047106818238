<template>
  <div class="home">
    <Title
        title="Integracja XML"
        subtitle="Integracja XML jest sposobem na przesyłanie dużej ilości ofert z systemów..."
    />
    <div class="nav">
      <router-link to="/import-xml">
        <SprButton
            title="Walidacja XML"
            subtitle="Zweryfikuj swój XML zanim nam go prześlesz"
        />
      </router-link>
      <SprButton
          title="Dokumentacja"
          subtitle="Dokumentacja integracji XML"
          disabled
      />
      <SprButton
          title="Wsparcie"
          subtitle="Masz problem z integracją? Napisz do nas."
          disabled
      />
    </div>
  </div>
</template>

<script>
import Title from "../components/SprTitle.vue";
import SprButton from "../components/SprButton.vue";

export default {
  name: "SprHome",
  components: { Title, SprButton },
};
</script>

<style scoped lang="scss">
.nav {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (max-width: 1000px) {
  .nav {
    display: block;
  }
}
</style>