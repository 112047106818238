<template>
  <div
    class="text-left rounded-md py-2 px-4 mb-5 drop-shadow-md border-b-4 border-green-400"
    :class="[className]"
  >
    {{ alertMessage }}
  </div>
</template>

<script>
export default {
  name: "SprAlert",
  props: {
    variant: {
      type: String,
      default: () => "success",
    },
    alertMessage: {
      type: String,
      required: true,
    },
  },
  computed: {
    className() {
      let classList = "";
      switch (this.variant) {
        case "success":
          classList = "bg-green-100 border-green-400";
          break;
        case "error":
          classList = "bg-red-100 border-red-400";
      }
      return classList;
    },
  },
};
</script>
