import axios from "axios";

export const API = fetch("/json/config.json")
  .then((resp) => resp.json())
  .then(() =>
    axios.create({
      baseURL: '/',
      timeout: 5000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
  );
