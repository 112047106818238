import { ref } from "vue";
import { API } from "@/api";

export function form() {
  let fileName = ref("Kliknij, aby wybrać plik"),
    showErrorLabel = ref(false),
    errorLabel = ref(""),
    showSuccessLabel = ref(false),
    successLabel = ref(""),
    fileData = ref(null);

  const fileChange = function (event) {
    this.fileName = event.target.value.split(/[\\ ]+/).pop();
    this.successLabel = 'test';
    this.fileData = event.target.files[0];
    console.log(fileData);
  };

  const handleSubmit = async function(file) {
    const Backend = await API;
    return Backend.post('/api/xml/validator', {
      attachment: file
    })
  }

  return {
    fileName,
    showErrorLabel,
    errorLabel,
    showSuccessLabel,
    successLabel,
    fileChange,
    handleSubmit,
  };
}
