<template>
  <div class="header">
    <router-link to="/">
      <img
          class="logo"
          src="@/assets/logo.png"
          srcset="@/assets/logo.png 1x, @/assets/logo@2x.png 2x"
          alt="sprzedajemy.pl - developers portal"
      />
    </router-link>
    <div class="subtitle">DEVELOPERS PORTAL</div>
  </div>
  <router-view />
</template>

<style lang="scss">
#app {
  max-width: 1024px;
  margin: 0 auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<style scoped lang="scss">
.header {
  text-align: left;

  .subtitle {
    cursor: default;
  }
}
</style>